import React, { useRef } from "react";
import { motion } from "framer-motion";
import NavbarSection from "./elements/Navbar";
import { useSelector } from "react-redux";
import Footer from "./elements/Footer";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com"; // Import EmailJS

export default function Contact() {
  const data = useSelector((state) => state.auth.public);
  const { t, i18n } = useTranslation();
  const form = useRef(); // Create a ref for the form

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent default form submission

    emailjs
      .sendForm(
        "service_nuv4agc",
        "template_0tummte",
        form.current,
        "wZpCflMwKmeH1qFxF"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("An error occurred, please try again.");
        }
      );
  };

  return (
    <>
      <NavbarSection data={data} />
      <motion.div
        className="pagebanner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="overlay"></div>
        <h1>{t("Contact us")}</h1>
      </motion.div>

      <motion.div
        id="menu"
        className="menu-products py-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="bg2"></div>
        <div className="container py-5">
          <div className="row contact-white">
            <motion.div
              className="col-md-5"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="contact-card">
                <h1 className="contact-title">{t("Contact Information")}</h1>
                <p className="contact-subtitle">
                  {t("Say something to start a chat!")}
                </p>
                <ul className="contact-info">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    <div className="row">
                      <div className="col-md-6" style={{ direction: "ltr" }}>
                        {i18n.language === "en"
                          ? data?.data.phone
                          : data?.data.phone_ar}
                      </div>
                      <div className="col-md-6" style={{ direction: "ltr" }}>
                        {i18n.language === "en"
                          ? data?.data.phone2
                          : data?.data.phone2_ar}
                      </div>
                      <div className="col-md-6" style={{ direction: "ltr" }}>
                        {i18n.language === "en"
                          ? data?.data.phone3
                          : data?.data.phone3_ar}
                      </div>
                      <div className="col-md-6" style={{ direction: "ltr" }}>
                        {i18n.language === "en"
                          ? data?.data.phone4
                          : data?.data.phone4_ar}
                      </div>
                    </div>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <div>
                      {i18n.language === "en" ? (
                        <>
                          <div className="arabic-text">
                            {data?.data.address}
                          </div>
                          <div>{data?.data.address2}</div>
                        </>
                      ) : (
                        <>
                          <div className="arabic-text">
                            {data?.data.address_ar}
                          </div>
                          <div>{data?.data.address2_ar}</div>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </motion.div>

            <motion.div
              className="col-md-7"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <form className="p-3" ref={form} onSubmit={sendEmail}>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("First Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder={t("First Name")}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Last Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder={t("Last Name")}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">{t("Email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder={t("Email")}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("Phone Number")}</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      placeholder={t("Phone Number")}
                      required
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="form-label">{t("Message")}</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="4"
                    placeholder={t("Message")}
                    required
                  ></textarea>
                </div>

                <div className="text-end">
                  <button type="submit" className="btn btn-send">
                    {t("Send Message")}
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="row">
          <div className="col-md-6">
            {data?.data.addressiframe ? (
              <iframe
                src={data?.data.addressiframe}
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : null}
          </div>
          <div className="col-md-6">
            {data?.data.addressiframe2 ? (
              <iframe
                src={data?.data.addressiframe2}
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : null}
          </div>
        </div>

        <div
          className="footer-line"
          style={{
            backgroundImage:
              "url(https://api.baderaldeensweets.com/public/storage/footer.png)",
          }}
        ></div>
      </motion.div>

      <Footer />
    </>
  );
}
