import React, { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Spinner from "./components/dashboard/shared/Spinner";
import Track from "./components/public/track";
import Product from "./components/public/Product";
import { loadCountry, loadPublic } from "./state/action-creator/auth-actions";
import SyrianMenu from "./components/public/SyrianMenu";
import AmmanMenu from "./components/public/AmmanMenu";
import Contact from "./components/public/Contact";
import News from "./components/public/News";
import About from "./components/public/About";
import Page from "./components/public/Page";
import Employment from "./components/public/Employment";
import Clients from "./components/public/Clients";
import Rates from "./components/public/Rates";

const Home = lazy(() => import("./components/public/Home"));
const Dashboard = lazy(() => import("./components/dashboard/index"));
const Login = lazy(() => import("./components/auth/Login"));
const Signup = lazy(() => import("./components/auth/Signup"));
const EmailVerify = lazy(() =>
  import("./components/dashboard/pages/EmailVerify")
);
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const ForgotPasswordReset = lazy(() =>
  import("./components/auth/ForgotPasswordReset")
);

const index = lazy(() => import("./components/public"));

const AppRoutes = () => {
  const state = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadPublic());
    dispatch(loadCountry());
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path="/" component={Home} exact />

        <Route path="/syria/" component={SyrianMenu} />
        <Route path="/amman/" component={AmmanMenu} />
        <Route path="/news/" component={News} />
        <Route path="/about/" component={About} />

        <Route path="/contact/" component={Contact} />
        <Route path="/careers/" component={Employment} />
        <Route path="/clients/" component={Clients} />
        <Route path="/rates/" component={Rates} />

        <Route path="/page/:slug" component={Page} />

        <Route path="/product/:id" component={Product} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/signup" component={Signup} />
        <PublicRoute path="/forgotpassword" component={ForgotPassword} />
        <PublicRoute
          path="/forgotpasswordreset"
          component={ForgotPasswordReset}
        />

        <Route path="/track/:id" component={Track} />

        <PrivateRoute authed={state} path="/dashboard" component={Dashboard} />

        <Route path="/order/:username" component={index} />

        <Route path="/auth/email/verify" component={EmailVerify} />

        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
